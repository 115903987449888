import {
  Card,
  Image,
  CardBody,
  Heading,
  CardFooter,
  Button,
  Link,
  Box,
  Text,
} from "@chakra-ui/react";

export default function SelectedCompany({ company }) {
  const imageUrl = company.square_image;

  return (
    <Card overflow="hidden" width="300px">
      <Box
        position="relative"
        height="150px"
        overflow="hidden"
        _after={{
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${imageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          filter: "blur(10px)",
          zIndex: 0,
          transform: "scale(1.1)",
        }}
      >
        <Image
          objectFit="contain"
          maxHeight="150px"
          maxWidth="300px"
          src={imageUrl}
          alt={company.name}
          position="relative"
          zIndex={1}
          margin="auto"
          display="block"
        />
      </Box>
      <CardBody paddingBottom="0px">
        <Heading size="sm">{company.name}</Heading>
        {/* <Text fontSize="sm">{company.description}</Text> */}
      </CardBody>
      <CardFooter padding={4}>
        <Link href={company.website} width="100%" isExternal>
          <Button
            variant="solid"
            colorScheme="black-btn"
            width="100%"
            backgroundColor="#276084"
          >
            Website
          </Button>
        </Link>
      </CardFooter>
    </Card>
  );
}
