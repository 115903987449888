import React, { useState } from "react";
import {
  Grid,
  GridItem,
  Heading,
  Text,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Center,
} from "@chakra-ui/react";
import { TreeMain } from "../sections/CompanyTree";
import Map from "../Map";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export default function DesktopLayout({
  companyCategories,
  handleSelectedCompany,
  outerBounds,
  setSelectedCompany,
  setMap,
  companies,
  selectedCompany,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <Grid
      templateAreas={`"header header"
                        "nav main"
                        "footer footer"`}
      gridTemplateRows={"0px 1fr"}
      gridTemplateColumns={"300px 1fr"}
      h="100vh"
      w="100vw"
      overflow={"hidden"}
      padding={4}
      textAlign={"left"}
    >
      <GridItem p="2" area={"nav"} overflowY="auto">
        <Grid
          templateRows="repeat(1, fr)"
          templateColumns="repeat(1, fr)"
          gap={4}
          h="full"
        >
          <GridItem rowSpan={1} colSpan={1}>
            <Grid
              templateRows="repeat(1, 1fr)"
              templateColumns="repeat(5, 1fr)"
              gap={0}
            >
              <GridItem rowSpan={2} colSpan={1}>
                <img src="/logo_large.png" style={{ display: "block" }} />
              </GridItem>
              <GridItem colSpan={4} p={0} pl={2}>
                <Heading color="#1A202C" fontSize="xl" p={0}>
                  cgt
                  <Heading fontSize="xl" as="span" color="#276084">
                    map.com
                  </Heading>
                </Heading>
                <Text fontSize="sm">
                  A centralised list of cell and gene therapy companies and
                  institutions.
                </Text>
              </GridItem>
            </Grid>
            <Center pt={4}>
              <Text fontSize="sm">
                <Link textDecoration={"underline"} onClick={openModal}>
                  About Us
                </Link>
              </Text>
            </Center>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} overflowY="auto">
            <TreeMain
              companies={companies}
              selectCompany={handleSelectedCompany}
            />
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem
        area={"main"}
        display={"block"}
        position="relative"
        borderRadius={"30px"}
        borderColor={"black"}
        bg="#ebf2f6"
      >
        <Map
          outerBounds={outerBounds}
          setSelectedCompany={setSelectedCompany}
          setMap={setMap}
          companies={companies}
          handleSelectedCompany={handleSelectedCompany}
          selectedCompany={selectedCompany}
        />
      </GridItem>
      <GridItem area="footer" textAlign="center"></GridItem>
      <Modal size={"xl"} isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={"sm"}>About Us</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontSize="sm">
            <Text>
              cgtmap.com is a centralised list of cell and gene therapy
              companies and institutions.
              <br />
              <br />
              cgtmap.com is created by: <br />
              <Link
                textDecoration={"underline"}
                href="https://www.linkedin.com/in/syd-mclean/"
                isExternal
              >
                Syd Mclean:
              </Link>
              {"\u00A0"}a PhD candidate in CAR-T manufacturing at University College London
              <br />
              <Link
                textDecoration="underline"
                href="https://www.linkedin.com/in/tom-mclean-/"
                isExternal
              >
                Tom McLean:
              </Link>
              {"\u00A0"}a software engineer at INEOS Britannia
            
                <br />
                <br />
                Our current mission is to map every CGT related institution in the UK. As we
              grow, we aim to roll out other features, including mapping
              worldwide, and highlighting who is hiring.
              <br />
              <br />
              If you would like to add or edit a listing, provide feature
              requests or have any other questions or comments, please{" "}
              <Link
                textDecoration={"underline"}
                href="https://forms.gle/DreSLyLQJvwZSzmR7"
                isExternal
              >
                let us know here <ExternalLinkIcon mx="2px" />
              </Link>
              .
            </Text>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Grid>
  );
}
