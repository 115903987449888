import MarkerClusterGroup from "react-leaflet-cluster";
import CompanyIcon from "./CompanyIcon";

export default function CompaniesMarkerGroup({ companies, onClick }) {
  return (
    <MarkerClusterGroup>
      {companies && companies.map && companies.map((company) => {
        return <CompanyIcon company={company} onClick={onClick} />;
      })}
    </MarkerClusterGroup>
  );
}
