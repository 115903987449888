import { divIcon } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { Marker } from "react-leaflet";

export default function CompanyIcon({ company, onClick }) {
  const companyIcon = `https://images.cgtmap.com/images/${company.id}.png`;

  const iconMarkup = renderToStaticMarkup(
    <div
      class="bottom-arrow"
      style={{
        background: "#276084",
        width: "50px",
        height: "50px",
        display: "block",
        position: "relative",
        padding: "10px",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          position: "absolute",
          top: "10px",
          left: "10px",
          bottom: "10px",
          right: "10px",
        }}
      >
        <img
          alt=""
          style={{ height: "100%", width: "100%" }}
          src={companyIcon}
        />
      </div>
    </div>
  );

  const customMarkerIcon = divIcon({
    className: "",
    iconSize: [50, 50],
    iconAnchor: [25, 50],
    popupAnchor: [1, -34],
    html: iconMarkup,
  });

  return (
    <Marker
      position={[company.lat, company.lon]}
      icon={customMarkerIcon}
      eventHandlers={{
        click: (e) => {
          onClick(e, company);
        },
      }}
    ></Marker>
  );
}
