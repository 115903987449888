import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: `'Noto Sans', sans-serif`,
    body: `'Noto Sans', sans-serif`,
  },
  fontWeights: {
    heading: 400, // Noto Sans Regular (normal weight)
    body: 300, // Noto Sans Light
  },
  fontSizes: {
    xs: "12px",
    sm: "12px",
    md: "12px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "28px",
    "4xl": "36px",
    "5xl": "48px",
    "6xl": "64px",
  },
  colors: {
    "black-btn": {
      50: "#f2f2f2",
      100: "#e6e6e6",
      200: "#1a1a1a",
      300: "#1a1a1a",
      400: "#1a1a1a",
      500: "#1a1a1a",
      600: "#1a1a1a",
      700: "#1a1a1a",
      800: "#1a1a1a",
      900: "#1a1a1a",
    },
  },
  components: {
    Drawer: {
      parts: ["dialog", "header", "body"],
      variants: {
        primary: {
          secondary: {
            dialog: {
              maxW: "1000px",
            },
          },
        },
      },
    },
  },
});

export default theme;
