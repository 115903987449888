import React, { useState } from "react";
import { Link, Box, Heading, Text, useDisclosure, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, IconButton, GridItem, Grid, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Center } from "@chakra-ui/react";
import { FaBars } from "react-icons/fa";
import CompanyTree, { TreeMain } from "./sections/CompanyTree";
import { ExternalLinkIcon } from "@chakra-ui/icons";
export default function Sidebar({ companies, handleSelectedCompany }) {
    const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
  

    // when a company is selected, close the sidebar
    const newHandleSelectedCompany = (company, zoom) => {
        handleSelectedCompany(company, zoom);
        onClose();
    };

    return (
        <>
            <IconButton onClick={onOpen} display={"contents"} aria-label="Open Menu" position={"absolute"} icon={<FaBars />} />
            <Drawer isOpen={isOpen} placement="left" onClose={onClose} position={"absolute"}>
                <DrawerOverlay />
                <DrawerContent padding={4}>
                    <DrawerCloseButton />
                    <Grid
                    templateRows="repeat(1, 1fr)"
                    templateColumns="repeat(5, 1fr)"
                    gap={0}
                    pb={4}
                    >
                        <GridItem rowSpan={2} colSpan={1}>
                            <img src="/logo_large.png" style={{ display: "block" }}/>
                        </GridItem>
                        <GridItem colSpan={4} p={0}>
                            <Heading color="#1A202C" fontSize="xl" p={0}>cgt<Heading fontSize="xl" as="span" color="#276084">map.com</Heading></Heading>
                            <Text fontSize="sm">
                            A centralised list of cell and gene therapy companies and institutions.
                            </Text>
                    </GridItem>
                    </Grid>
                    <Center pt={0}>
                        <Text fontSize="sm">
                            <Link textDecoration={"underline"} onClick={openModal}>
                            About Us
                            </Link>
                        </Text>
                    </Center>
                    <Box overflowY="auto">
                        <TreeMain companies={companies} selectCompany={newHandleSelectedCompany} />
                    </Box>
                </DrawerContent>
            </Drawer>
            <Modal size={"xl"} isOpen={isModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader fontSize={"sm"}>About Us</ModalHeader>
                <ModalCloseButton />
                <ModalBody fontSize="sm">
                    <Text>
              cgtmap.com is a centralised list of cell and gene therapy
              companies and institutions.
              <br />
              <br />
              cgtmap.com is created by: <br />
              <Link
                textDecoration={"underline"}
                href="https://www.linkedin.com/in/syd-mclean/"
                isExternal
              >
                Syd Mclean:
              </Link>
              {"\u00A0"}a PhD candidate in CAR-T manufacturing at University College London
              <br />
              <Link
                textDecoration="underline"
                href="https://www.linkedin.com/in/tom-mclean-/"
                isExternal
              >
                Tom McLean:
              </Link>
              {"\u00A0"}a software engineer at INEOS Britannia
              
                <br />
                <br />
                Our current mission is to map every CGT related institution in the UK. As we
              grow, we aim to roll out other features, including mapping
              worldwide, and highlighting who is hiring.
              <br />
              <br />
              If you would like to add or edit a listing, provide feature
              requests or have any other questions or comments, please{" "}
              <Link
                textDecoration={"underline"}
                href="https://forms.gle/DreSLyLQJvwZSzmR7"
                isExternal
              >
                let us know here <ExternalLinkIcon mx="2px" />
              </Link>
              .
            </Text>
                </ModalBody>
                <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
