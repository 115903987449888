import axios from "axios";

export default async function getCompanyData() {
  const skip = 0;
  const limit = 1000;
  const response = await axios.get(
    "https://api.cgtmap.com/company?get_hidden=false&limit=1000",
    {
      params: {
        skip,
        limit,
      },
    }
  );

  const companies = response.data.map((company) => {
    return {
      id: company.id,
      name: company.name,
      lat: company.position.coordinates[1],
      lon: company.position.coordinates[0],
      logo: company.logo,
      description: company.description,
      website: company.website,
      category: company.category,
      icon: company.icon,
      banner_image: company.banner_image,
      square_image: `https://images.cgtmap.com/images/${company.id}.png`,
    };
  });

  companies.sort((a, b) => a.name.localeCompare(b.name));

  return companies;
}
